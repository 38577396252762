/**
 * Author: Jérôme Koch
 */

/**
 * Is used for layout and UI reasons. Can convert abbreviations of cantons to the full canton names.
 */
const cantonConverter = {
    AG: "Aargau", AR: "Appenzell Ausserrhoden", AI: "Appenzell Innerrhoden", BL: "Basel-Land", BS: "Basel-Stadt",
    BE: "Bern", FR: "Freiburg", GE: "Genf", GL: "Glarus", GR: "Graubünden", JU: "Jura", LU: "Luzern",
    NE: "Neuenburg", NW: "Nidwalden", OW: "Obwalden", SG: "St. Gallen", SH: "Schaffhausen", SO: "Solothurn",
    SZ: "Schwyz", TG: "Thurgau", TI: "Tessin", UR: "Uri", VS: "Wallis", VD: "Waadt", ZG: "Zug", ZH: "Zürich",
}

/**
 * A const Array that contains every abbreviation of the TARIFF_ZONES / Cantons
 */
const CANTON_ARRAY = ['AG', 'AR', 'AI', 'BL', 'BS', 'BE', 'FR', 'GE', 'GL', 'GR', 'JU', 'LU', 'NE',
    'NW', 'OW', 'SG', 'SH', 'SO', 'SZ', 'TG', 'TI', 'UR', 'VS', 'VD', 'ZG', 'ZH'];

export {cantonConverter, CANTON_ARRAY};
