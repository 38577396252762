import {cantonConverter} from "../utils/CantonData"

const ERROR_BASE = "Ein Fehler ist aufgetreten -  ";
const ERROR_INVALID_REQUEST = "Die Anfrage enthält einen Fehler"; //todo which errors are important here?
const ERROR_INTERNAL_ERROR = "Interner Fehler";
const ERROR_MESSAGE = "Die Anfrage kann nicht verarbeitet werden";


/**
 * This function uses the connected backend to fill an object with the calculated prices from the backend.
 * @param fAddress is the adress of the backend-connection
 * @param stateObject contains all data from the fronend form (InputFrom.js)
 * @returns void, though the calcData-Object is changed in the process of this function.
 */

const processBackendCall = (fetchAdress, stateObject, token) => {
    const formData = getStateDataAsJSON(stateObject);
    return fetch(fetchAdress, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: formData
    })
        .then(response => response.json())
        .then(rJSON =>
            processResponse(rJSON)
        )
        .catch(err => showErrorMessage(ERROR_MESSAGE, err));
}

const processResponse = (rJSON) => {
    if (rJSON.status === 400) {
        showErrorMessage(ERROR_INVALID_REQUEST, rJSON.status);
        return;
    }

    if (rJSON.status === 500) {
        showErrorMessage(ERROR_INTERNAL_ERROR, rJSON.status);
        return;
    }
    let calcData = {};
    let finalPrice = 0;


    calcData.data = [];
    calcData.id = "";
    rJSON.tariffResponseZoneList.forEach(canton => {
        finalPrice += canton.price;
        let name = canton.zoneName.toString();
        calcData.data = [...calcData.data, {
            "name": name,
            "canton": cantonConverter[name],
            "price": canton.price.toString(),
        }];
    });
    calcData["id"] = rJSON.id;
    calcData["responseId"] = rJSON.responseId;
    calcData["resultPrice"] = finalPrice;
    calcData["additionalService"] = {
        "needsTransportbegleitung": rJSON.needsTransportbegleitung,
        "needsPolizeibegleitung": rJSON.needsPolizeibegleitung,
        "needsConstruction": rJSON.needsConstruction,
        "needsNachtfahrt" :rJSON.needsNachtfahrt
    }
    return calcData;
}

const processResponseSimple = (rJSON) => {
    if (rJSON.status === 400) {
        showErrorMessage(ERROR_INVALID_REQUEST, rJSON.status);
        return;
    }

    if (rJSON.status === 500) {
        showErrorMessage(ERROR_INTERNAL_ERROR, rJSON.status);
        return;
    }

    const calcData = {};
    calcData.data = [];
    calcData.id = "";
    calcData["resultPrice"] = rJSON.toString();
    return calcData;
}

const showErrorMessage = (message, log) => {
    console.error(ERROR_BASE + message, log);
    alert(ERROR_BASE + message);
}
/**
 * This function uses the connected backend to fill an object with the calculated prices from the backend.
 * @param fetchAdress is the adress of the backend-connection
 * @param calcData is an object that will be filled with information from a backend-post-call.
 * @param stateObject contains all data from the fronend form (InputFrom.js)
 * @param token keycloak access token
 * @returns void, though the calcData-Object is changed in the process of this function.
 */
const processBackendCallSimple = (fetchAdress, stateObject) => {
    const formData = getStateDataAsJSON(stateObject);
    return fetch(fetchAdress, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: formData
    })
        .then(response => response.json())
        .then(rJSON =>
            processResponseSimple(rJSON)
        )
        .catch(err => showErrorMessage(ERROR_MESSAGE, err));
}


/**
 * Uses the stateObject to turn its data into a json-string.
 * @param stateObject contains all data from the fronend form (InputFrom.js)
 * @returns a JSON-String version of the state-object
 */
const getStateDataAsJSON = (stateObject) => {
    return JSON.stringify({
        orderName: stateObject.orderName,
        height: stateObject.height,
        width: stateObject.width,
        length: stateObject.length,
        weight: stateObject.weight,
        frontOverhang: stateObject.front_overhang,
        backOverhang: stateObject.back_overhang,
        tariffZones: stateObject.tariff_zones,
    });
}

export {processBackendCall, processBackendCallSimple};

