/* backend api url pattern */
export const FRONTEND_BASE_URL = process.env.REACT_APP_BASE_URL
export const BACKEND_BASE_URL = process.env.REACT_APP_API_URL

export const BACKEND_TARIFF_SELECTED_ZONES = 'api/tariff/selectedZones/';
export const BACKEND_TARIFF_SELECTED_COORDINATES = 'v1/tariff/selectedCoordinates/';
export const BACKEND_TARIFF_SIMPLE = 'simple'

export const BACKEND_CUSTOMER_REQUEST_URL = 'api/requests/request/new'
export const BACKEND_CHANGELOG_URL = 'data/changelog'
export const BACKEND_ORDERFEEDBACK_URL='feedback/new'
export const BACKEND_USER_OVERVIEW_URL = 'api/user/orders'
export const BACKEND_USER_DELETE_URL = 'api/user/delete'

export const BACKEND_ADDITIONAL_SERVICES = 'api/additional-service/add'

/* keycloak config */
export const KEYCLOAK_BASE_URL = process.env.REACT_APP_KEYCLOAK_URL
export const KEYCLOAK_REALM = "atb24"
export const KEYCLOAK_CLIENT_ID = "atb24-app"
