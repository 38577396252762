import React from "react";

import "../StaticBusinessPage/StaticBusinessPage.css"
import "./PrivacyPage.css"
import language from "./PrivacyPage.lan.json"

const lan = language.DE;

const PrivacyPage = () => {
    return (
        <div className="tac-container">
            <h4>{lan.title}</h4>
            <div className="tac-line"/>
            <div className="tac-content">
                <h3>{lan.header1}</h3>
                <p>{lan.paragraph1}</p>
                <p><a href="https://www.atb24.ch/" className="privacy-link">https://www.atb24.ch</a></p>
            </div>

            <div className="tac-content">
                <h3>{lan.header2}</h3>
                <p>{lan.paragraph2}</p>
                <p><a href="https://www.google.com/intl/de/tagmanager/use-policy.html" className="privacy-link">https://www.google.com/intl/de/tagmanager/use-policy.html</a></p>

            </div>

            <div className="tac-content">
                <h3>{lan.header3}</h3>
                <p>{lan.paragraph3}</p>
                <p><a href="https://de-de.facebook.com/about/privacy" className="privacy-link"> https://de-de.facebook.com/about/privacy </a></p>
            </div>

            <div className="tac-content">
                <h3>{lan.header4}</h3>
                <p>{lan.paragraph4}</p>
                <p><a href="https://twitter.com/privacy" className="privacy-link"> https://twitter.com/privacy </a></p>
            </div>

            <div className="tac-content">
                <h3>{lan.header5}</h3>
                <p>{lan.paragraph5}</p>
                <p><a href="http://instagram.com/about/legal/privacy/" className="privacy-link"> http://instagram.com/about/legal/privacy </a></p>
            </div>

            <div className="tac-content">
                <h3>{lan.header6}</h3>
                <p>{lan.paragraph6_1}</p>
                <p><a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" className="privacy-link"> https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out </a></p>
                <p>{lan.paragraph6_2}</p>
            </div>

            <div className="tac-content">
                <h3>{lan.header7}</h3>
                <p>{lan.paragraph7}</p>
            </div>

            <div className="tac-content">
                <h3>{lan.header8}</h3>
                <p>{lan.paragraph8}</p>
            </div>

            <div className="tac-content">
                <h3>{lan.header9}</h3>
                <p>{lan.paragraph9}</p>
            </div>

            <div className="tac-content">
                <h3>{lan.header10}</h3>
                <p>{lan.paragraph10}</p>
            </div>

            <div className="tac-content">
                <h3>{lan.header11}</h3>
                <p>{lan.paragraph11}</p>
            </div>

            <div className="tac-content">
                <h3>{lan.header12}</h3>
                <p>{lan.paragraph12_1}</p>
                <br/>
                <p>{lan.paragraph12_2}</p>
                <p>{lan.paragraph12_3}</p>
            </div>

        </div>
    );
}

export default PrivacyPage;
