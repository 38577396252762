import React from "react";

import "./Cantons.css"

import {CANTON_ARRAY} from "../../../../utils/CantonData";

const Cantons = (props) => {
    const handleCheckboxChange = (event) => {
        let temp_zones = props.calcData.tariff_zones;
        let check = event.target.checked;
        let checked_canton = event.target.value;
        if (check) {
            document.getElementById("canton-map").style.backgroundColor = "transparent";
            props.setCalcData((prevState) => {
                return {...prevState, tariff_zones: [...props.calcData.tariff_zones, checked_canton]};
            })
        } else {
            let index = temp_zones.indexOf(checked_canton);
            if (index > -1) {
                temp_zones.splice(index, 1);
                props.setCalcData((prevState) => {
                    return {...prevState, tariff_zones: temp_zones};
                })
            }
        }
    }
    return (
        <div id="canton-map" className="cantons-container">
            {CANTON_ARRAY.map((canton) => {
                return (
                    <div key={canton + "-cb"} className={canton}>
                        <input className="canton-checkbox" type="checkbox" id={canton + "-cb"}
                               name={canton}
                               value={canton}
                               onChange={e =>
                                   handleCheckboxChange(e)
                               }/>
                        <label className="canton-label" id={canton + "-lb"}>
                            {canton}
                        </label>
                    </div>
                );
            })}
            <div className="AS">
                <input className="astra-checkbox" type="checkbox" id="AS-cb" name="AS"
                       value="Astra"
                       onChange={e =>
                           handleCheckboxChange(e)
                       }
                />
                <label className="astra-label" id="AS-lb">
                    ASTRA
                </label>
            </div>
        </div>
    );
}

export default Cantons;

