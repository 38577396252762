/**
 * This function uses the connected backend to fill an object with the calculated prices from the backend.
 * @param fetchAddress
 * @param data the data as object (not as json string)
 *   example:
 *   data = {
            "transportbegleitung": {
                "treffpunkt": {
                    "place": "Brugg",
                    "date": "2012-04-23T18:25:43.511Z"
                },
                "person": {
                    "firstname": "Tobii",
                    "lastname": "Keycloak",
                    "phone": "05689",
                    "language": "CSS"
                }
            },
            "polizeibegleitung": null,
            "routeInspection": null,
            "montage": false,
            "comment": "I am a comment :))))))",
            "offeringOrderId": 1
            }
 * @param files An array of FileLists
 *   example:
 *   files = [uploadButton1State, uploadButton2State]
 * @param token keycloak access token
 */
export const orderAdditionalService = async (fetchAddress, data, files, token) => {
    let formData = new FormData();

    //append each file from each FileList to the form
    for (let i = 0; i < files.length; i++) {
        for (let j = 0; j < files[i].length; j++) {
            formData.append("files", files[i][j], files[i][j].name);
        }
    }

    data = JSON.stringify(data)
    formData.append("additionalServices", data);

    return fetch(fetchAddress, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: formData
    });
}

/**
 * This function uses the connected backend to fill an object with the calculated prices from the backend.
 * @param fetchAddress
 * @param token keycloak access token
 * @returns void, though the calcData-Object is changed in the process of this function.
 */
export const orderPolizeibegleitung = async (fetchAddress, token, formData) => {
    const req = await fetch(fetchAddress, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: formData
    });

    if (req.status !== 400) {
        return req.json();
    }
}
