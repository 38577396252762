import React from "react";
import {Route} from "react-router-dom";
import ServicePage from "./ServicesPage/ServicePage";
import SonderbewilligungPage from "./SonderbewilligungPage/SonderbewilligungPage";
import Impressum from "./ImpressumPage/ImpressumPage";
import Privacy from "./PrivacyPage/PrivacyPage.jsx";


const BusinessPages = () => {
    return (
        <>
            <Route path="/service">
                <ServicePage/>
            </Route>
            <Route path="/sonderbewilligung">
                <SonderbewilligungPage/>
            </Route>
            <Route path="/privacy">
                <Privacy/>
            </Route>
            <Route path="/impressum">
                <Impressum/>
            </Route>

        </>
    );
}

export default BusinessPages;

