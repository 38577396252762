import React, {useEffect, useState} from "react";
import {ensureLoggedIn, isAdmin, isUser, keycloak} from "../../../user/Keycloak";
import getAccountInformation from "../../../api/accountAPI";
import "./Account.css"
import language from "./Account.lan.json";
import {BACKEND_BASE_URL} from "../../../api/apiConfig"


const lan = language.DE;

const Account = () => {
    const [profile, setProfile] = useState({});
    const fetchAddress = BACKEND_BASE_URL + "api/user/userinfo";

    


    useEffect(() => {
        getAccountInformation(fetchAddress, keycloak.token)
            .then(data => data.json())
            .then(data => setProfile(data))
    }, [fetchAddress])


    return (
        <>
            {ensureLoggedIn() && (
                <div className="account-container">
                    <div className="account-card">
                        <p>{lan.email}</p>
                        <hr/>
                        <h4>{profile.email}</h4>
                        <p>{lan.firstName}</p>
                        <hr/>
                        <h4>{profile.firstName}</h4>
                        <p>{lan.lastName}</p>
                        <hr/>
                        <h4>{profile.lastName}</h4>
                        <p>{lan.uuid}</p>
                        <hr/>
                        <h4>{profile.uuid}</h4>
                        <div className="account-bottom">
                            <div className="account-line"/>
                            <button onClick={keycloak.accountManagement}>{lan.button}</button>
                            <div className="account-break"/>
                            <h2>{lan.calc}</h2>
                            <div className="account-break"/>
                            <div className="bottom-button-div">
                                <button onClick={() => window.location.href = "/"}>{lan.back}</button>
                                {isUser() && <a href="/user">
                                    <button className="overview-button">{lan.user_orders_btn}</button>
                                </a>}

                                {isAdmin() && <a href="/admin">
                                    <button className="overview-button">{lan.admin_orders_btn}</button>
                                </a>}
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Account;
