import React from "react";

import "../StaticBusinessPage/StaticBusinessPage.css"
import language from "./ImpressumPage.lan.json"

const lan = language.DE;

const ImpressumPage = () => {
    return (
        <div className="tac-container">
            <h4>{lan.title}</h4>
            <div className="tac-line"/>
            <div className="tac-content">
                <h3>{lan.header1}</h3>
                <p>{lan.addr1}</p>
                <p>{lan.addr2}</p>
                <p>{lan.addr3}</p>
                <p>{lan.addr4}</p>
                <p>{lan.addr5} {lan.addr6}</p>
            </div>
            <div className="tac-content">
                <h3>{lan.header2}</h3>
                <p>{lan.line1}</p>
                <p>{lan.line2}</p>
                <p>{lan.line3}</p>
                <p>{lan.line4}</p>
            </div>
            <div className="tac-content">
                <h3>{lan.header3}</h3>
                <p>{lan.paragraph3}</p>
            </div>
            <div className="tac-content">
                <h3>{lan.header4}</h3>
                <p>{lan.paragraph4}</p>
            </div>
            <div className="tac-content">
                <h3>{lan.header5}</h3>
                <p>{lan.paragraph5}</p>
            </div>
            <div className="tac-content">
                <h3>{lan.header6}</h3>
                <p>{lan.paragraph6}</p>
            </div>
        </div>
    );
}

export default ImpressumPage;
