import React from "react";
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import language from "./CalcTable.lan.json"
import "./CalcTable.css";

const lan = language.DE;

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableFooterCell = withStyles((theme) => ({
    body: {
        backgroundColor: "gray",
        color: theme.palette.common.white,
        fontSize: "large"
    },
}))(TableCell);


const CalcTable = ({cantonData}) => {
    const rows = cantonData.data;

    return (
        <div id="calc-table" className="table-div">
            <TableContainer component={Paper}>
                <Table>
                    <colgroup>
                        <col style={{width:'33%'}}/>
                        <col style={{width:'34%'}}/>
                        <col style={{width:'33%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{lan.shortCanton}</StyledTableCell>
                            <StyledTableCell align="right">{lan.canton}</StyledTableCell>
                            <StyledTableCell align="right">{lan.price}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">{row.canton}</TableCell>
                                <TableCell align="right">{row.price}</TableCell>
                            </StyledTableRow>
                        ))}
                        <TableRow>
                            <StyledTableFooterCell>{cantonData.id ? "ID " + cantonData.id : ""}</StyledTableFooterCell>
                            <StyledTableFooterCell align="right">Gesamtpreis:</StyledTableFooterCell>
                            <StyledTableFooterCell
                                align="right">{cantonData.resultPrice} CHF</StyledTableFooterCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );


}

export default CalcTable;

