import React, {useEffect, useState} from "react";
import XLSX from "xlsx";
import keycloak from "../../../user/Keycloak";
import {delOrder, getOrders} from "../../../api/ordersAPI";
import {BACKEND_BASE_URL, BACKEND_USER_OVERVIEW_URL} from "../../../api/apiConfig"
import delete_button from "../../../resources/delete-button.svg"

/**
 * Datatable
 */
import DataTable, {createTheme} from 'react-data-table-component';

/**
 * CSS
 */
import "./OrderTable.css"


/**
 * Language File
 */
import language from "./OrderTable.lan.json"

const lan = language.DE;

/**
 * Datatable theme
 */
createTheme('dark', {
    text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(0,0,0,.12)',
    },
    background: {
        default: '#424242',
    },
    context: {
        background: '#E91E63',
        text: '#FFFFFF',
    },
    divider: {
        default: 'rgba(81, 81, 81, 1)',
    },
    button: {
        default: '#FFFFFF',
        focus: 'rgba(255, 255, 255, .54)',
        hover: 'rgba(255, 255, 255, .12)',
        disabled: 'rgba(255, 255, 255, .18)',
    },
    sortFocus: {
        default: 'rgba(255, 255, 255, .54)',
    },
    selected: {
        default: 'rgba(0, 0, 0, .7)',
        text: '#FFFFFF',
    },
    highlightOnHover: {
        default: 'rgba(0, 0, 0, .7)',
        text: '#FFFFFF',
    },
    striped: {
        default: 'rgba(255, 255, 255, .54)',
        text: '#FFFFFF',
    },
});

/**
 * Datatable custom styles
 */
const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};


const OrderTable = ({user}) => {
    const [showMod, setShowMod] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    window.onclick = function (event) {
        if (event.target === document.getElementById("modal-order-delete")) {
            setShowMod(false);
        }
    }

    const convertFetchOrdersForTable = orders => {
        return orders.map(order => {
            const date = new Date(order["createdAt"] * 1000)
            let row = {
                id: order["id"],
                orderName: order["tariffRequest"]["orderName"],
                created: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`,
                category: order["tariffResponse"]["category"],
                length: order["tariffRequest"]["length"],
                width: order["tariffRequest"]["width"],
                height: order["tariffRequest"]["height"],
                weight: order["tariffRequest"]["weight"],
                front_overhang: order["tariffRequest"]["frontOverhang"],
                back_overhang: order["tariffRequest"]["backOverhang"],
            }
            if (!user) {
                row["user"] = order["userEmail"];
            }
            return row;
        })
    }

    /**
     * Datatable setup
     */
    const columns = [
        {name: 'ID', selector: 'id', sortable: true, right: true,},
        {name: "Name der Bestellung", selector: "orderName", sortable: true,},
        {name: lan.created, selector: "created", sortable: true, right: true,},
        {name: lan.category, selector: "category", sortable: true,},
        {name: lan.length, selector: "length", sortable: true, right: true,},
        {name: lan.width, selector: "width", sortable: true, right: true,},
        {name: lan.height, selector: "height", sortable: true, right: true,},
        {name: lan.weight, selector: "weight", sortable: true, right: true,},
        {name: lan.front_overhang, selector: "front_overhang", sortable: true, right: true,},
        {name: lan.back_overhang, selector: "back_overhang", sortable: true, right: true,},
        {name: "Optionen", selector: "delete",},
        {
            name: 'Actions', cell: row => <img onClick={() => showModal(row.id)} //deleteOrder(row.id, console.log)
                                               className="order-table-delete-button"
                                               src={delete_button} alt="delete-button"
            />
        }
    ];

    if (!user) {
        columns.splice(1, 0, {name: "User", selector: "user", sortable: true});
    }


    const showModal = (rowId) => {
        setShowMod(true);
        document.getElementById("modal-btn-cancel").onclick = function () {
            setShowMod(false);
        }

        document.getElementById("alert-close-btn").onclick = function () {
            setShowAlert(false);
        }
        document.getElementById("modal-btn-delete").onclick = function () {
            setShowMod(false);
            deleteOrder(rowId, setOrders);
            setShowAlert(true);
            setTimeout(function () {
                setShowAlert(false);
            }, 3000);
        }

    }

    const deleteOrder = (id, setOrders) => {
        setOrders(curr => curr.filter(x => x.id !== id))
        delOrder(id, keycloak.token)
            .catch(console.error)
    }

    /* converts Date.now to date with utc offset */
    function getDateWithUTCOffset(inputTzOffset) {
        var now = new Date(); // get the current time

        var currentTzOffset = -now.getTimezoneOffset() / 60 // in hours, i.e. -4 in NY
        var deltaTzOffset = inputTzOffset - currentTzOffset; // timezone diff

        var nowTimestamp = now.getTime(); // get the number of milliseconds since unix epoch
        var deltaTzOffsetMilli = deltaTzOffset * 1000 * 60 * 60; // convert hours to milliseconds (tzOffsetMilli*1000*60*60)
        var outputDate = new Date(nowTimestamp + deltaTzOffsetMilli) // your new Date object with the timezone offset applied.

        return outputDate;
    }

    /* converts table to workbook */
    function exportFile(array) {
        const ws = XLSX.utils.json_to_sheet(array.map(row => prepRow(row)));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "atb24.ch Berechnungen");
        /* generate XLSX file and send to client */
        const now = getDateWithUTCOffset(4);
        XLSX.writeFile(wb, "atb24.ch_Berechnungen_" + now.toISOString() + ".xlsx");
    }

    /* export table button */
    const Export = ({onExport}) => (
        <button className="export-button" onClick={e => onExport(e.target.value)}>{lan.table_export_btn}</button>
    );

    const api = `${BACKEND_BASE_URL}${BACKEND_USER_OVERVIEW_URL}${user ? "/last" : "/all"}`
    const [orderRows, setOrders] = useState([])
    /* export table button */
    const exportAction = <Export onExport={() => exportFile(orderRows)}/>;

    useEffect(() => {
        getOrders(api, keycloak.token)
            .then(orders => convertFetchOrdersForTable(orders))
            .then(orders => setOrders(orders))
    }, [api])

    /**
     * This method renames the column names in order to show the correct names.
     */
    const prepRow = (row) => {
        let prepped = {};
        prepped["ID"] = row.id;
        if (!user) {
            prepped["User"] = row.user;
        }
        prepped[lan.order_name] = row.orderName;
        prepped[lan.created] = row.created;
        prepped[lan.category] = row.category;
        prepped[lan.length] = row.length;
        prepped[lan.width] = row.width;
        prepped[lan.height] = row.height;
        prepped[lan.weight] = row.weight;
        prepped[lan.front_overhang] = row.front_overhang;
        prepped[lan.back_overhang] = row.back_overhang;
        return prepped;
    }

    return (

        <div className="page-Container">
            <div id="alert" className="alert success" style={showAlert ? {display: "block"} : {display: "none"}}>
                <span id="alert-close-btn" className="alert-close-btn">&times;</span>
                {lan.alert_text}
            </div>
            <div id="modal-order-delete" className="modal" style={showMod ? {display: "block"} : {display: "none"}}>
                <span id="modal-order-delete-close-btn" className="close"
                      onClick={() => setShowMod(true)}>&times;</span>
                <div className="modal-content">
                    <div className="container">
                        <h1>{lan.modal_header}</h1>
                        <p>{lan.modal_infotext}</p>

                        <div className="clearfix">
                            <button id="modal-btn-cancel" type="button"
                                    className="modal-btn cancelbtn">{lan.modal_btn_cancel}</button>
                            <button id="modal-btn-delete" type="button"
                                    className="modal-btn deletebtn">{lan.modal_btn_delete}</button>
                        </div>
                    </div>
                </div>
            </div>
            <button className="back-button" onClick={() => window.location.href = "/account"}>{lan.btn_back}</button>

            <div className="order-table-container">
                <div className="order-table-wrapper">

                    <DataTable
                        title={user ? lan.user_title : lan.admin_title}
                        columns={columns}
                        data={orderRows}
                        theme="dark"
                        //striped
                        responsive
                        //dense -> compact rows, try it out
                        customStyles={customStyles}
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        paginationPerPage={5}
                        actions={exportAction}
                        fixedHeader
                    />

                </div>
            </div>
        </div>
    );
}


export default OrderTable;
