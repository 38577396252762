import React from "react";
import {kcLogin, kcLogout, keycloak} from "../../user/Keycloak"
import account_icon from "../../resources/account-icon.svg"

/**
 * CSS
 */
import "./Header.css";

/**
 * Language File
 */
import language from "./Header.lan.json";

/**
 * Ressources
 */
import {HEADER_LOGO} from "../../resources/textModules"

const lan = language.DE;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Header= () => {

    return (
        <nav>
            <div className="header-logo" id="headerLogo">
                <a href="/"><h1>{HEADER_LOGO}</h1></a>
            </div>
            <ul className="nav-links">
                <li><a href="/">{lan.home}</a></li>
                <li><a href="/#about">{lan.about}</a></li>
                <li><a href="/#calculation">{lan.calculation}</a></li>
                <li><a href="/contact">{lan.contact}</a></li>
                {keycloak.authenticated
                    ? <>
                        <li><button onClick={kcLogout} className="log-button">LOGOUT</button></li>
                        <li><a href="/account"><img className="account-icon" src={account_icon} alt="account"/></a></li>
                    </>
                    : <li><button className="log-button" onClick={kcLogin}>{lan.login}</button></li>
                }
            </ul>
            <div className="burger" onClick={navSlide}>
                <div className="line1"/>
                <div className="line2"/>
                <div className="line3"/>
            </div>
        </nav>
    );
}

export default Header;

const navSlide = () => {
    const burger = document.querySelector(".burger");
    const nav = document.querySelector(".nav-links");
    const navLinks = document.querySelectorAll(".nav-links li");

    nav.classList.toggle("nav-active");
    
    navLinks.forEach((link, index) => {
        if (link.style.animation) {
            link.style.animation = ""
        } else {
            link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.5}s`;
        }
    });
    burger.classList.toggle("toggle");
}