import "./calculationUtil.css"
import React from "react";

const printValueRow = (lan, values) => {
    let calcData = JSON.parse(JSON.stringify(values))
    for (const data in calcData) {
        let value = calcData[data];
        if (value === "") {
            calcData[data] = 0;
        }
    }
    return (
        <div className="valueRow">
            <h1>Ihre Berechnung:</h1>
            <p className="rowTitle">In der Tabelle sehen sie die Preisberechnung die sich aus folgenden werten
                ergibt:</p>
            <p> {lan.length}: {calcData.length}m, {lan.width}: {calcData.width}m, {lan.height}: {calcData.height}m, {lan.weight}: {calcData.weight}t, {lan.front_overhang}: {calcData.front_overhang}m, {lan.back_overhang}: {calcData.back_overhang}m
            </p>
        </div>
    );
}

export default printValueRow;