const getChangelog = async (fAddress) => {
    return fetch(fAddress, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
}

export default getChangelog;