import React, {useEffect, useState} from "react";
import Cantons from "./Cantons/Cantons";
import {Button, Form} from "react-bootstrap";
import calculationSubmit from "./CalculationSubmit";
import printValueRow from "../../../utils/calculationUtil";


/**
 * CSS
 */
import "./Calculation.css";


/**
 * Language File
 */
import language from "./Calculation.lan.json";
import CalcTable from "./CalcTable/CalcTable";
import {kcLogin, keycloak} from "../../../user/Keycloak";

/**
 * Ressources
 */

const lan = language.DE;

/**
 * Calculation component that is used to calculate tariffs
 * @returns {JSX.Element} : A functional component
 * @constructor
 */



const Calculation = ({setAdditionalService}) => {
    const [textAdditionalServices, setTextAdditionalServices] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [calcData, setCalcData] = useState({
        length: "",
        width: "",
        height: "",
        weight: "",
        front_overhang: "",
        back_overhang: "",
        tariff_zones: [],
        fetchOption: "",
        fetchOptionSelected: false,
        orderName: ""
    })
    const [tableData, setTableData] = useState({})

    /**
     * Used to change the state if input in InputForm has changed.
     */
    const handleChange = e => {
        let name = e.target.name;
        let value = e.target.value;
        setCalcData((prevState) => {
            return {...prevState, [name]: value};
        });
    }

    /**
     * Used to bubble up the additionalService state
     */
    useEffect(() => {
        if (isSubmitted && tableData && tableData.additionalService) {
            setAdditionalService({
                transport_escort: tableData.additionalService.needsTransportbegleitung,
                police_escort: tableData.additionalService.needsPolizeibegleitung,
                re_de_assembly: tableData.additionalService.needsConstruction,
                night_transport: tableData.additionalService.needsNachtfahrt,
                responseId: tableData.responseId
            })

            let text = "";
            if (tableData.additionalService.needsTransportbegleitung) {
                text += "Eigenbegleitung mit Gelblicht (Ziff. 7)\n";
            }

            if (tableData.additionalService.needsPolizeibegleitung) {
                text += "Polizeibegleitung (ATB) (Ziff. 5)\n";
            }

            if (tableData.additionalService.needsConstruction) {
                text += "Re-/Demontage\n";
            }

            if (tableData.additionalService.needsNachtfahrt) {
                text += "Nachtfahrt\n";
            }

            setTextAdditionalServices(text);

        }
        if(window.location.hash) {
            const hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
            document.getElementById(hash).scrollIntoView();
        }
    }, [isSubmitted, setAdditionalService, tableData]);

    const handleReset = () => {
        document.getElementById("fetch-options").style.backgroundColor = "rgba(44, 63, 80, 0.05)";
        document.getElementById("canton-map").style.backgroundColor = "transparent";
        setIsSubmitted(false);
        resetCheckboxes();
        setTableData({});
        setCalcData({
            length: "",
            width: "",
            height: "",
            weight: "",
            front_overhang: "",
            back_overhang: "",
            tariff_zones: [],
            fetchOption: "",
            fetchOptionSelected: false,
            orderName: ""
        })
        setTextAdditionalServices("");
        setAdditionalService({
            transport_escort: false,
            police_escort: false,
            re_de_assembly: false,
            night_transport: false,
            route_check: false
        });
        document.getElementById("fetch-options").scrollIntoView({block: "center"})
    }
    const resetCheckboxes = () => {
        if (!calcData.tariff_zones) {
            return;
        }
        calcData.tariff_zones.forEach((name) => {
            if (name === "Astra") {
                name = "AS";
            }
            const cb = document.getElementById(name + "-cb");
            if (cb.checked) {
                document.getElementById(name + "-cb").checked = false;
            }
        })
    }


    const handleFetchOptionChange = (e) => {
        setCalcData((prevState) => {
            return {
                ...prevState,
                fetchOptionSelected: e.target.value !== "none",
                fetchOption: e.target.value
            };
        })
        document.getElementById("AS-cb").checked = e.target.value === "import";
        document.getElementById("AS-cb").disabled = e.target.value === "import";
        document.getElementById("fetch-options").style.backgroundColor = "rgba(44, 63, 80, 0.05)";
    }

    /**
     disable scrolling on input fields
     */
    const inputTypeNumbers = document.querySelectorAll("input[type=number]");
    for (const inputTypeNumber of inputTypeNumbers) {
        inputTypeNumber.onwheel = function (event) {
            event.target.blur();
        };
    }

    return (
        <Form onReset={handleReset}
              onSubmit={e => calculationSubmit(e, setIsSubmitted, calcData, setTableData)}>

            <div id="calculation" className="calculation-container" hash={'surprise'}>
                <h4>{lan.calculate_tariff}</h4>
                <div className="calculation-line"/>

                <div className="cform-div calculation-steps"
                     style={keycloak.authenticated ? {display: "flex"} : {display: "none"}}>
                    <label className="cform-steps-label">{lan.reference_step}
                    </label>
                </div>

                <div className="cform-div calculation-steps-note"
                     style={keycloak.authenticated ? {display: "none"} : {display: "flex"}}>
                    <label className="cform-steps-label-title">{lan.note}</label>
                    <label className="cform-steps-label">{lan.note_text}<br/>
                    </label>
                </div>

                <div className="button-row" style={keycloak.authenticated ? {display: "none"} : {display: "flex"}}>
                    <button className="calculation-log-button" onClick={kcLogin}>{lan.login}</button>
                </div>

                <div className="cform-div" style={keycloak.authenticated ? {display: "flex"} : {display: "none"}}>
                    <input className="cform-input" id="order-name" type="text" name="orderName"
                           placeholder="&nbsp;"
                           value={calcData.orderName} onChange={handleChange}/>
                    <label className="cform-label" htmlFor="order-name">{lan.orderName}</label>
                </div>

                <div className="cform-div calculation-steps">
                    <label className="cform-steps-label-title">{lan.step1}</label>
                    <label className="cform-steps-label">{lan.step1_text1}<br/>{lan.step1_text2}
                    </label>
                </div>

                <div className="cform-div fa fa-angle-down">
                    <select className="cform-select" id="fetch-options"
                            value={calcData.fetchOption} onChange={handleFetchOptionChange}>
                        <option defaultChecked={true} value="none">{lan.dropdownMessage}</option>
                        <option value="import">{lan.import}</option>
                        <option value="export">{lan.export}</option>
                        <option value="intercantonal">{lan.intercantonal}</option>
                    </select>
                    <div className="arrow-down">
                        <div className="arrow1"/>
                        <div className="arrow2"/>
                    </div>
                </div>

                <div className="cform-div calculation-steps">
                    <label className="cform-steps-label-title">{lan.step2}</label>
                    <label className="cform-steps-label">{lan.step2_text1}<br/>{lan.step2_text2}</label>
                </div>

                <Cantons setCalcData={setCalcData} calcData={calcData}/>

                <div className="cform-div calculation-steps">
                    <label className="cform-steps-label-title">{lan.step3}</label>
                    <label
                        className="cform-steps-label">{lan.step3_text1}<br/>{lan.step3_text2}<br/>{lan.step3_text3}<br/>{lan.step3_text4}
                    </label>
                </div>

                <div className="cform-div">
                    <input className="cform-input" id="input-length" type="number" name="length"
                           placeholder="&nbsp;"
                           min={0} max={60} step={0.01} value={calcData.length} onChange={handleChange}/>
                    <label className="cform-label" htmlFor="input-length">{lan.length} {lan.meters}</label>
                </div>
                <div className="cform-div">
                    <input className="cform-input" id="input-width" type="number" name="width"
                           placeholder="&nbsp;"
                           min={0} max={10} step={0.01} value={calcData.width} onChange={handleChange}/>
                    <label className="cform-label" htmlFor="input-width">{lan.width} {lan.meters}</label>
                </div>
                <div className="cform-div">
                    <input className="cform-input" id="input-height" type="number" name="height"
                           placeholder="&nbsp;"
                           min={0} max={8} step={0.01} value={calcData.height} onChange={handleChange}/>
                    <label className="cform-label" htmlFor="input-height">{lan.height} {lan.meters}</label>
                </div>
                <div className="cform-div">
                    <input className="cform-input" id="input-weight" type="number" name="weight"
                           placeholder="&nbsp;"
                           min={0} max={200} step={0.01} value={calcData.weight} onChange={handleChange}/>
                    <label className="cform-label" htmlFor="input-weight">{lan.weight} {lan.tons}</label>
                </div>
                <div className="cform-div">
                    <input className="cform-input" id="input-front_overhang" type="number"
                           name="front_overhang"
                           placeholder="&nbsp;"
                           min={0} max={15} step={0.01} value={calcData.front_overhang}
                           onChange={handleChange}/>
                    <label className="cform-label"
                           htmlFor="input-front_overhang">{lan.front_overhang} {lan.meters}</label>
                </div>
                <div className="cform-div">
                    <input className="cform-input" id="input-back_overhang" type="number"
                           name="back_overhang"
                           placeholder="&nbsp;"
                           min={0} max={15} step={0.01} value={calcData.back_overhang}
                           onChange={handleChange}/>
                    <label className="cform-label"
                           htmlFor="input-back_overhang">{lan.back_overhang} {lan.meters}</label>
                </div>

                <div className="cform-div calculation-steps">

                    <label className="cform-steps-label-title">{lan.step4}</label>
                    <label className="cform-steps-label">{lan.step4_text1}</label>
                </div>

                <div className="button-row">
                    <Button id="submit-button" type="submit"
                            className="input-button">{lan.calculate}</Button>
                    <Button id="reset-button" type="reset"
                            className="input-button">{lan.reset}</Button>
                </div>
                {isSubmitted && tableData.data && <div>{printValueRow(lan,calcData)}</div>}
                {isSubmitted && tableData.data && <CalcTable cantonData={tableData}/>}

                {textAdditionalServices !== "" && (<div className="additionalService-text-header">
                    {lan.expected_services}
                </div>)}
                <div className="additionalService-text">
                    {textAdditionalServices}
                </div>

                {isSubmitted && !keycloak.authenticated && (
                    <div className="cform-div calculation-steps">
                        <label className="cform-steps-label">{lan.schmackhaft}</label>
                    </div>
                )}
            </div>
        </Form>
    );
}

export default Calculation;
