import Keycloak from 'keycloak-js'

const FRONTEND_BASE_URL = process.env.REACT_APP_BASE_URL
const KEYCLOAK_BASE_URL = process.env.REACT_APP_KEYCLOAK_URL
const KEYCLOAK_REALM = "atb24"
const KEYCLOAK_CLIENT_ID = "atb24-app"

const keycloakConfig = {
    url: KEYCLOAK_BASE_URL,
    realm: KEYCLOAK_REALM,
    clientId: KEYCLOAK_CLIENT_ID
}

export const keycloak = Keycloak(keycloakConfig);

export const kcLogin = () => {
    keycloak.login()
}

export const kcLogout = () => {
    keycloak.logout({ redirectUri: FRONTEND_BASE_URL });
}

export function ensureLoggedIn() {
    if (!keycloak.authenticated) kcLogin();
    return true
}

export function isLoggedIn() {
    return keycloak.authenticated
}
export function isUser() {
    keycloak.authenticated && keycloak.resourceAccess["atb24-app"].roles.indexOf('user')
    return keycloak.authenticated && keycloak.resourceAccess["atb24-app"].roles.indexOf('user') > -1
}

export function isAdmin() {
    return keycloak.authenticated && keycloak.resourceAccess["atb24-app"].roles.indexOf('admin') > -1
}

export default keycloak;
