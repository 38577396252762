/**
 * GLOBAL MODULES
 */

export const ATB_EMAIL = "info@atb24.ch";
export const ATB_TEL = "+41 62 824 99 88";
export const ATB_STREET = "Marchenweg 6";
export const ATB_PLZ = "5035";
export const ATB_CITY = "Unterentfelden";
export const ATB_COUNTRY = "Schweiz";

/**
 * Text modules for COMPONENTS.
 * Best practice: {COMPONENT_NAME}_{TEXT_NAME} = <>{HTML}</>
 */

export const HEADER_LOGO = <>ATB<span>24</span>.ch</>;
export const HOME_LOGO = HEADER_LOGO;
export const FOOTER_LOGO = HEADER_LOGO;


