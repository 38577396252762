import React, {useEffect, useState} from 'react';
import "./Changelog.css"
import getChangelog from "../../api/changelogAPI";

/**
 * @Author: Maxim Lavoie
 * This module implements the changelog for our releases and changes
 */
const Changelog = () => {
    const [changelog, setChangelog] = useState([])
    const API_CHANGELOG_URL = "api/data/changelog";

    useEffect(() => {
        getChangelog(process.env.REACT_APP_API_URL + API_CHANGELOG_URL)
            .then(changelog => changelog.json())
            .then(changelog => setChangelog(changelog))
            .catch(err => console.error(err))
    }, []);


    /**
     * This method converts a link from text to a clickable link element.
     * @param feature
     */
    const createLinkElement = feature => {
        if (feature.includes("http")) {
            return (<li>{feature.split(/(?=http)/)[0]} <a
                href={feature.split(/(?=http)/)[1]}>{feature.split(/(?=http)/)[1]}</a></li>)
        }
        return <li>{feature}</li>
    }
    return (
        <div className="changelog-container">
            <h1>Changelog</h1>
            <div className="changelog-title-line" />
            <div className="changelog-changes">
                {changelog.map(item => {
                    return (
                        <div>
                            <h2>{item.date} | {item.event}</h2>
                            <div className="changelog-line" />
                            <ul>
                                {item.features.map(feature => {
                                    return (
                                        <li>{createLinkElement(feature)}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Changelog;
