import React, { useEffect, useState } from "react";
import { isLoggedIn, keycloak } from "../../user/Keycloak";
import TB_IMAGE from "../../resources/AdditionalService/begleitung.svg";
import PB_IMAGE from "../../resources/AdditionalService/police.svg";
import MON_IMAGE from "../../resources/AdditionalService/montage.svg";
import RI_IMAGE from "../../resources/AdditionalService/route.svg";
import NI_IMAGE from "../../resources/AdditionalService/night.svg";

/**
 * CSS
 */
import "./OrderAdditionalService.css";

/**
 * Language File
 */
import language from "./OrderAdditionalService.lan.json";
import { Button, Form } from "react-bootstrap";
import {
    BACKEND_ADDITIONAL_SERVICES,
    BACKEND_BASE_URL,
} from "../../api/apiConfig";
import { orderAdditionalService } from "../../api/additionalServicesAPI";

const lan = language.DE;

/**
 * Form for additional services
 * @returns {JSX.Element} : A functional component
 * @constructor
 */
const url = BACKEND_BASE_URL + BACKEND_ADDITIONAL_SERVICES;

const OrderAdditionalService = ({ additionalService }) => {
    const [features, setFeatures] = useState({
        transportbegleitung: additionalService["transport_escort"],
        polizeibegleitung: additionalService["police_escort"],
        montage: additionalService["re_de_assembly"],
        routeInspection: additionalService["route_check"],
        nachtfahrt: additionalService["night_transport"],
        start: "",
        end: "",
        cargo: "",
        comment: "",
    });

    useEffect(() => {
        setFeatures({
            ...features,
            transportbegleitung: additionalService.transport_escort,
            polizeibegleitung: additionalService.police_escort,
            montage: additionalService.re_de_assembly,
            routeInspection: additionalService.route_check,
            nachtfahrt: additionalService.night_transport,
        });
    }, [additionalService]);

    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        if (!isLoggedIn()) {
            return;
        }

        if (
            !features.transportbegleitung &&
            !features.polizeibegleitung &&
            !features.montage &&
            !features.nachtfahrt &&
            !features.routeInspection
        ) {
            document
                .getElementById("submit-button-services")
                .classList.add("disabled");
            setButtonDisabled(true);
        } else {
            document
                .getElementById("submit-button-services")
                .classList.remove("disabled");
            setButtonDisabled(false);
        }
    }, [features]);

    const clear = () => {
        setFeatures({
            transportbegleitung: additionalService["transport_escort"],
            polizeibegleitung: additionalService["police_escort"],
            montage: additionalService["re_de_assembly"],
            routeInspection: additionalService["route_check"],
            nachtfahrt: additionalService["night_transport"],
            start: "",
            end: "",
            cargo: "",
            comment: "",
        });
    };

    const addSpinner = () => {
        document.getElementById("button-spinner").classList.add("loading");
    };

    const removeSpinner = () => {
        document.getElementById("button-spinner").classList.remove("loading");
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        let files = [];

        if (!isValid()) {
            return;
        }

        features.offeringOrderId = additionalService.responseId;

        addSpinner();
        orderAdditionalService(url, features, files, keycloak.token)
            .then((req) => {
                if (req.status !== 400) {
                    alert(lan.alert1);
                }
                clear();
            })
            .catch((error) => {
                console.error(error);
                alert(lan.alert2);
            })
            .finally(() => {
                removeSpinner();
            });
    };

    const isValid = () => {
        let valid = true;

        // text fields
        document.getElementById("input-start").classList.remove("invalid");
        document.getElementById("input-end").classList.remove("invalid");
        document.getElementById("input-cargo").classList.remove("invalid");

        if (features.start === "") {
            document.getElementById("input-start").classList.add("invalid");
            valid = false;
        }
        if (features.end === "") {
            document.getElementById("input-end").classList.add("invalid");
            valid = false;
        }
        if (features.cargo === "") {
            document.getElementById("input-cargo").classList.add("invalid");
            valid = false;
        }

        return valid;
    };
    return (
        <>
            {isLoggedIn() && (
                <Form onSubmit={handleSubmit}>
                    <div id="additionalService" className="forms-container">
                        <div className="cform-div service-steps additionalService">
                            <label className="cform-steps-label-title">
                                {lan.step6}
                            </label>
                            <label className="cform-steps-label">
                                {lan.step6_text1}
                                <br />
                                <br />
                            </label>
                        </div>

                        <div className="as-cb-container">
                            <div className="as-box">
                                <img
                                    src={TB_IMAGE}
                                    alt=""
                                    className={`as-button ${
                                        features.transportbegleitung
                                            ? "as-button-active"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setFeatures({
                                            ...features,
                                            transportbegleitung:
                                                !features.transportbegleitung,
                                            polizeibegleitung: false
                                        });
                                    }}
                                />

                                <label
                                    className="as-cb-label"
                                    htmlFor="input-vehicle"
                                >
                                    {lan.service_transport}
                                </label>
                            </div>

                            <div className="as-box">
                                <img
                                    src={PB_IMAGE}
                                    alt=""
                                    className={`as-button ${
                                        features.polizeibegleitung
                                            ? "as-button-active"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setFeatures({
                                            ...features,
                                            polizeibegleitung:
                                                !features.polizeibegleitung,
                                            transportbegleitung: false
                                        });
                                    }}
                                />

                                <label
                                    className="as-cb-label"
                                    htmlFor="input-vehicle"
                                >
                                    {lan.service_police}
                                </label>
                            </div>

                            <div className="as-box">
                                <img
                                    src={MON_IMAGE}
                                    alt=""
                                    className={`as-button ${
                                        features.montage
                                            ? "as-button-active"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setFeatures({
                                            ...features,
                                            montage: !features.montage,
                                        });
                                    }}
                                />
                                <label
                                    className="as-cb-label"
                                    htmlFor="input-vehicle"
                                >
                                    {lan.service_montage}
                                </label>
                            </div>

                            <div className="as-box">
                                <img
                                    src={RI_IMAGE}
                                    alt=""
                                    className={`as-button ${
                                        features.routeInspection
                                            ? "as-button-active"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setFeatures({
                                            ...features,
                                            routeInspection:
                                                !features.routeInspection,
                                        });
                                    }}
                                />

                                <label
                                    className="as-cb-label"
                                    htmlFor="input-vehicle"
                                >
                                    {lan.service_route}
                                </label>
                            </div>

                            <div className="as-box">
                                <img
                                    src={NI_IMAGE}
                                    alt=""
                                    className={`as-button ${
                                        features.nachtfahrt
                                            ? "as-button-active"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setFeatures({
                                            ...features,
                                            nachtfahrt:
                                                !features.nachtfahrt,
                                        });
                                    }}
                                />

                                <label
                                    className="as-cb-label"
                                    htmlFor="input-vehicle"
                                >
                                    {lan.service_night}
                                </label>
                            </div>
                        </div>
                        <div className="cform-div">
                            <input
                                type="text"
                                name="start"
                                id="input-start"
                                className="offer-cform-input"
                                placeholder="&nbsp;"
                                value={features.start}
                                onChange={(e) => {
                                    setFeatures({
                                        ...features,
                                        start: e.target.value,
                                    });
                                    document
                                        .getElementById("input-start")
                                        .classList.remove("invalid");
                                }}
                            />

                            <label
                                className="offer-cform-label"
                                htmlFor="input-comment"
                            >
                                {lan.start}
                            </label>
                        </div>

                        <div className="cform-div">
                            <input
                                type="text"
                                name="end"
                                id="input-end"
                                className="offer-cform-input"
                                placeholder="&nbsp;"
                                value={features.end}
                                onChange={(e) => {
                                    setFeatures({
                                        ...features,
                                        end: e.target.value,
                                    });
                                    document
                                        .getElementById("input-end")
                                        .classList.remove("invalid");
                                }}
                            />

                            <label
                                className="offer-cform-label"
                                htmlFor="input-comment"
                            >
                                {lan.end}
                            </label>
                        </div>

                        <div className="cform-div">
                            <input
                                type="text"
                                name="cargo"
                                id="input-cargo"
                                className="offer-cform-input"
                                placeholder="&nbsp;"
                                value={features.cargo}
                                onChange={(e) => {
                                    setFeatures({
                                        ...features,
                                        cargo: e.target.value,
                                    });
                                    document
                                        .getElementById("input-cargo")
                                        .classList.remove("invalid");
                                }}
                            />

                            <label
                                className="offer-cform-label"
                                htmlFor="input-comment"
                            >
                                {lan.transportgut}
                            </label>
                        </div>

                        <div className="cform-div">
                            <input
                                type="text"
                                name="comment"
                                id="input-comment"
                                className="offer-cform-input"
                                placeholder="&nbsp;"
                                value={features.comment}
                                onChange={(e) => {
                                    setFeatures({
                                        ...features,
                                        comment: e.target.value,
                                    });
                                }}
                            />

                            <label
                                className="offer-cform-label"
                                htmlFor="input-comment"
                            >
                                {lan.comment}
                            </label>
                        </div>

                        <div>
                            <br />
                            <Button
                                id="submit-button-services"
                                type="submit"
                                disabled={buttonDisabled}
                                className="disabled input-button"
                            >
                                <i id="button-spinner" />
                                <span>{lan.submit_button}</span>
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </>
    );
};

export default OrderAdditionalService;
