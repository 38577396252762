import React from "react";

import "./Redirection.css"
import CardButton from "./CardButton/CardButton";

import language from "./Redirection.lan.json"
import route from "../../../resources/Redirection/route.svg"
import services from "../../../resources/Redirection/services.svg"
import keycloak from "../../../user/Keycloak";


const lan = language.DE;

const Redirection = () => {
    return (
        <div className="redirection-container">
            <a href="/#calculation">
                <CardButton image={route} content={lan.calculate}/>
            </a>
            {keycloak.authenticated && <a href="/#additionalService">
                <CardButton image={services} content={lan.order_services}/>
            </a>}
        </div>
    );
}

export default Redirection;
