import React from "react";

/**
 * CSS
 */
import "./About.css";

/**
 * Language File
 */
import language from "./About.lan.json";

/**
 * Ressources
 */
import pic from "../../../resources/About/atb_team.jpg";

const lan = language.DE;

/**
 * About section that displays some information (text) and a picture.
 * @returns {JSX.Element} : Static component
 * @constructor
 */
const About = () => {
    return (
        <div id="about" className="about-container">
            <div className="about-left">
                <p className="about-title">{lan.title}</p>
                <p className="about-paragraph">{lan.paragraph1}</p>
                <p className="about-paragraph">{lan.paragraph2}</p>
                <p className="small-title">{lan.header1}</p>
                <ul className="about-list">
                    <li className="milestone">{lan.milestone1}</li>
                    <li className="milestone">{lan.milestone2}</li>
                    <li className="milestone">{lan.milestone3}</li>
                </ul>
            </div>
            <div className="about-line"/>
            <div>
                <img src={pic} alt="about"/>
            </div>
        </div>
    );
}

export default About;
