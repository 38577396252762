import React from "react";

/**
 * CSS
 */
import "./Footer.css";

/**
 * Language File
 */
import language from "./Footer.lan.json"

/**
 * Ressources
 */
import {ATB_CITY, ATB_COUNTRY, ATB_EMAIL, ATB_PLZ, ATB_STREET, ATB_TEL, FOOTER_LOGO} from "../../resources/textModules";
import MAIL_ICON from "../../resources/Footer/mail-icon.svg";
import PHONE_ICON from "../../resources/Footer/phone-icon.svg";
import MAP_ICON from "../../resources/Footer/map-icon.svg";
import CONTACTFORM_ICON from "../../resources/Footer/contactform-icon.svg";

const lan = language.DE;

/**
 * Footer component that displays some links and a logo
 * @returns {JSX.Element} : Static component
 * @constructor
 */
const Footer = () => {
    return (
        <div id="contact" className="footer-container">
            <div className="footer-logo">
                <h4>{FOOTER_LOGO}</h4>
            </div>
            <ul className="footer-column fc-transition fc-nolist-pad1">
                <p className="footer-title">{lan.more_about_us}</p>
                <div className="footer-line"/>
                <li><a href={"/#about"}>{lan.about_us}</a></li>
                <li><a href={"/service"}>{lan.service}</a></li>
                <li><a href={"/impressum"}>{lan.imprint}</a></li>
                <li><a href={"/privacy"}>{lan.tac}</a></li>
                <li><a href="/changelog">{lan.changelog}</a></li>
                <li><a href="/sonderbewilligung">{lan.sonderbewilligung}</a></li>
            </ul>
            <ul className="footer-column fc-nolist-pad1">
                <p className="footer-title">{lan.contact}</p>
                <div className="footer-line"/>
                <li>
                    <img src={MAIL_ICON} alt="mail"/>
                    <a href={'mailto:' + ATB_EMAIL}>{ATB_EMAIL}</a>
                </li>
                <li>
                    <img src={PHONE_ICON} alt="phone"/>
                    <a href={'tel:' + ATB_TEL}>{ATB_TEL}</a>
                </li>
                <li>
                    <img src={CONTACTFORM_ICON} alt="form"/>
                    <a href="/contact">{lan.contact_form}</a>
                </li>
                <li>
                    <img src={MAP_ICON} alt="map"/>
                    <p>{ATB_STREET}<br/>{ATB_PLZ} {ATB_CITY}<br/>{ATB_COUNTRY}</p>
                </li>

            </ul>
        </div>
    );
}

export default Footer;
