import {BACKEND_BASE_URL, BACKEND_USER_OVERVIEW_URL} from "./apiConfig"

/**
 * This function uses the connected backend to fill an object with the calculated prices from the backend.
 * @param fetchAddress
 * @param token keycloak access token
 * @returns void, though the calcData-Object is changed in the process of this function.
 */
export const getOrders = async (fetchAddress, token) => {
    const req = await fetch(fetchAddress, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
    return req.json();
}

export const delOrder = async (id, token) => {
    await fetch(`${BACKEND_BASE_URL}${BACKEND_USER_OVERVIEW_URL}/delete/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });
}
