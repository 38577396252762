import React, {Component} from 'react';
import './ContactForm.css';
import {BACKEND_CUSTOMER_REQUEST_URL} from '../../api/apiConfig';

import language from "./ContactForm.lan.json"

/**
 * @Author: Maxim Lavoie
 * This Module implements a contact form to contact MAMO, it currently writes an entry into our database.
 */

const lan = language.DE;

export class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: ''
        }
    }

    /**
     * This method uses a POST-Request, to send a message.
     * @param e
     */
    handleSubmit(e) {
        e.preventDefault();

        // alert("Die Nachricht wurde übermittelt, vielen Dank!")

        fetch(process.env.REACT_APP_API_URL + BACKEND_CUSTOMER_REQUEST_URL, {
            method: "POST",
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(response => (response.ok)
        ).then(response => {
            if (response) {
                alert('Nachricht versendet')
                this.resetForm()
                window.location.href = "/"
            }
        })
            .catch(err => console.log(err));
    }

    resetForm() {
        this.setState({name: "", email: "", message: "", phone: ""})
    }

    render() {
        return (
            <div className="contact-form-container">
                <h1>{lan.title}</h1><br/>
                <div className="cf-line" />
                <form className="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <div className="cf-div">
                        <label htmlFor="name">{lan.name}:<span>*</span></label>
                        <input type="text" id="name" value={this.state.name}
                               onChange={this.onNameChange.bind(this)} required/>
                    </div>
                    <div className="cf-div">
                        <label htmlFor="email">{lan.email}:<span>*</span></label>
                        <input type="email" id="email" aria-describedby="emailHelp"
                               value={this.state.email} onChange={this.onEmailChange.bind(this)} required/>
                    </div>
                    <div className="cf-div">
                        <label htmlFor="phone">{lan.phone}:<span>*</span></label>
                        <input type="tel" className="form-control" id="phone"
                               value={this.state.phone || ''} onChange={this.onPhoneChange.bind(this)} required/>
                    </div>
                    <div className="cf-div">
                        <label htmlFor="message">{lan.message}:<span>*</span></label>
                        <textarea rows="5" id="message" value={this.state.message}
                                  onChange={this.onMessageChange.bind(this)} required/>
                    </div>
                    <p><span className="cf-red-span">*</span>{lan.required}</p>
                    <button className="cf-button" type="submit">{lan.button}</button>
                </form>
            </div>
        );
    }

    onPhoneChange(event) {
        this.setState({phone: event.target.value})
    }

    onNameChange(event) {
        this.setState({name: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onMessageChange(event) {
        this.setState({message: event.target.value})
    }
}

export default ContactForm;