import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./user/Keycloak";

import Header from "./components/Header/Header";
import Home from "./components/Frontpage/Home/Home";
import About from "./components/Frontpage/About/About";
import Redirection from "./components/Frontpage/Redirection/Redirection";
import Calculation from "./components/Frontpage/Calculation/Calculation";
import Footer from "./components/Footer/Footer";
import OrderTable from "./components/UserOverview/OrderTable/OrderTable";
import OrderAdditionalService from "./components/OrderAdditionalService/OrderAdditionalService";
import Account from "./components/UserOverview/Account/Account";
import BusinessPages from "./components/BusinessPages/BusinessPages";
import { ContactForm } from "./components/ContactForm/ContactForm";
import Changelog from "./components/Changelog/Changelog.jsx";

const loadingComponent = <div className="spinner" />;

const App = () => {
    const [additionalService, setAdditionalService] = useState({
        transport_escort: false,
        police_escort: false,
        re_de_assembly: false,
        route_check: false,
        night_transport: false,
    });

    return (
        <ReactKeycloakProvider authClient={keycloak} LoadingComponent={loadingComponent} >
        <Router>
            <Header />
            <Route exact path="/">
                <Home />
                <About />
                <Redirection />
                <Calculation setAdditionalService={setAdditionalService} />
                <OrderAdditionalService
                    additionalService={additionalService}
                    setAdditionalService={setAdditionalService}
                />
            </Route>
            <Route path="/user">
                <OrderTable user={true} />
            </Route>
            <Route path="/additional-service">
                <OrderAdditionalService
                    additionalService={additionalService}
                    setAdditionalService={setAdditionalService}
                />
            </Route>
            <Route path="/admin">
                <OrderTable user={false} />
            </Route>

            <Route path="/account">
                <Account />
            </Route>
            <Route path="/contact">
                <ContactForm />
            </Route>
            <Route path="/changelog">
                <Changelog />
            </Route>

            <BusinessPages />

            <Footer />
        </Router>
        </ReactKeycloakProvider>
    );
};

export default App;
