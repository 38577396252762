import React from "react";

import "../StaticBusinessPage/StaticBusinessPage.css"
import "./SonderbewilligungPage.css"
import language from "./SonderbewilligungPage.lan.json"

const lan = language.DE;

const SonderbewilligungPage = () => {
    return (
        <div className="tac-container">
            <h2 className="sonderbewilligung-center">{lan.title}</h2>
            <div className="tac-line"/>

            <div className="tac-content">
                <h3>{lan.header1}</h3>
                <p>{lan.paragraph1}</p>
                <p><a href={lan.link1} className="sonderbewilligung-link">{lan.link1}</a></p>
            </div>

            <div className="tac-content">
                <h3>{lan.paragraph2}</h3>
                <ul className="sonderbewilligung-no-bullets">
                    <li>{lan.enumeration1}</li>
                    <li>{lan.enumeration2}</li>
                    <li>{lan.enumeration3}</li>
                    <li>{lan.enumeration4}</li>
                    <li>{lan.enumeration5}</li>
                    <li>{lan.enumeration6}</li>
                    <li>{lan.enumeration7}</li>
                </ul>
            </div>
            <div className="tac-content">
                <h3>{lan.paragraph3}</h3>
                <ul className="sonderbewilligung-no-bullets">
                    <li>{lan.addr1}</li>
                    <li>{lan.addr2}</li>
                    <li>{lan.addr3}</li>
                    <li>{lan.addr4}</li>
                </ul>
            </div>
            <div className="tac-content">
                <p className="red">{lan.paragraph4}</p>
                <p>{lan.paragraph5}</p>
                <a href={lan.mailto}>{lan.mail}</a>
            </div>

        </div>
    );
}

export default SonderbewilligungPage;
