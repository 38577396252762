import React from "react";

import "../StaticBusinessPage/StaticBusinessPage.css"
import language from "./ServicePage.lan.json"
import {keycloak, kcLogin} from "../../../user/Keycloak";

/**
 * CSS
 */
import "./ServicePage.css";

const lan = language.DE;


const ServicePage = () => {
    return (
        <div className="tac-container">
            <h4>{lan.title}</h4>
            <div className="tac-line"/>
            <div className="tac-content">
                <h3>{lan.header1}</h3>
                <p>{lan.paragraph1}</p>
            </div>
            <div className="tac-content">
                <h3>{lan.header2}</h3>
                <p>{lan.paragraph2}</p>
            </div>
            <div className="tac-content">
                <h5>{lan.header9}</h5>
                <p>{lan.paragraph10}</p>
                <a href='/#calculation'><button className="service-button">{lan.calculation}</button></a>
            </div>
            <div className="tac-content">
                <h3>{lan.header3}</h3>
                <p>{lan.paragraph3}</p>
            </div>
            <div className="tac-content">
                <h5>{lan.header4}</h5>
                <p>{lan.paragraph4}</p>
            </div>
            <div className="tac-content">
                <h5>{lan.header5}</h5>
                <p>{lan.paragraph5}</p>
            </div>
            <div className="tac-content">
                <h5>{lan.header6}</h5>
                <p>{lan.paragraph6}</p>
            </div>
            <div className="tac-content">
                <h5>{lan.header7}</h5>
                <p>{lan.paragraph7}</p>
            </div>
            <div className="tac-content">
                <h5>{lan.header8}</h5>

                <div style={keycloak.authenticated ? {display: "none"} : {display: "block"}}>
                    <p>{lan.paragraph8}</p>
                    <button className="service-button" onClick={kcLogin}>{lan.login}</button>
                </div>

                <div style={keycloak.authenticated ? {display: "block"} : {display: "none"}}>
                    <p>{lan.paragraph9}</p>
                    <a href='/#additionalService'>
                        <button className="service-button">{lan.offer}</button>
                    </a>
                </div>
            </div>
            </div>
    );
}

export default ServicePage;
