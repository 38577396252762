import React from "react";

/**
 * CSS
 */
import "./Home.css"

/**
 * Language File
 */
import language from "./Home.lan.json";

/**
 * Ressources
 */
import {HOME_LOGO} from "../../../resources/textModules"
import MapSwitzerland from "../../../resources/Home/SVG_Switzerland.svg";

const lan = language.DE;

/**
 * Home screen of our website. Used to show a small description about our site and a nice graphic. Is not functional
 * @returns {JSX.Element} Home screen with the map of switzerland and a small description
 * @constructor
 */
const Home = () => {
    return (
        <div id="home" className="home-container">
            <div className="home-image">
                <img src={MapSwitzerland} alt="Map Switzerland" />
            </div>
            <div className="home-description">
                <p>{lan.welcome}</p>
                <h4 className="home-logo">{HOME_LOGO}</h4>
                <p>{lan.description}</p>
                <a href="/service"><button className="welcome-button">{lan.button}</button></a>
            </div>
        </div>
    );
}

export default Home;
