import React from "react";

import "./CardButton.css"


const CardButton = (props) => {
    return (
        <div className="cardbutton-container">
            <img src={props.image} alt="cardButton"/>
            <p>{props.content}</p>
        </div>
    );
}

export default CardButton;