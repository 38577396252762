import keycloak from "../../../user/Keycloak";
import {processBackendCall, processBackendCallSimple} from "../../../api/calculationAPI";

const calculationSubmit = (e, setIsSubmitted, calcData, setTableData) => {
    const BACKEND_BASE_URL = process.env.REACT_APP_API_URL;
    const BACKEND_TARIFF_SELECTED_ZONES = 'api/tariff/selectedZones/';
    e.preventDefault();
    setIsSubmitted(false);
    let url = BACKEND_BASE_URL + BACKEND_TARIFF_SELECTED_ZONES + calcData.fetchOption;
    if (calcData.fetchOptionSelected) { // check if a fetch option is selected
        if (calcData.tariff_zones.length !== 0) { //check if cantons are selected
            if (keycloak.authenticated) { // check if the user is logged in
                processBackendCall(url, calcData, keycloak.token)
                    .then(data => {
                        //data may be undef / null when there is an error!
                        if (data == null) {
                            return;
                        }
                        setTableData(data);
                        setIsSubmitted(true);
                        document.getElementById("calc-table").scrollIntoView();
                        document.getElementById("calc-table").scrollIntoView({block: "center"});

                    });
            } else {
                processBackendCallSimple(url + "/simple", calcData)
                    .then(data => {
                        //data may be undef / null when there is an error!
                        if (data == null) {
                            return;
                        }
                        setTableData(data);
                        setIsSubmitted(true);
                        document.getElementById("calc-table").scrollIntoView({block: "center"});
                    });
            }
        } else {
            document.getElementById("canton-map").style.backgroundColor = "rgba(255, 0, 0, 0.3)";
            document.getElementById("canton-map").scrollIntoView({block: "center"});

        }
    } else {
        document.getElementById("fetch-options").style.backgroundColor = "rgba(255, 0, 0, 0.3)";
        document.getElementById("fetch-options").scrollIntoView({block: "center"});

    }
}

export default calculationSubmit;